/**
 * 、、
 */

class MemoryRoll {

  list = [];

  index = 0;

  hasRedo = false;

  hasUndo = false;

  maxCount = 50;

  bindShortKeys(){
    const el = this.editor.el;
    el.addEventListener("keyup",this.shortKeyupHandler.bind(this));
  }

  shortKeyupHandler(e){
    const key = e.keyCode || e.which;
    if(e.ctrlKey){
      if(key == 90){
        this.undo();
      }
      if(key == 89){
        this.redo();
      }
      e.preventDefault();
    }
  }

  undo(){
    if(this.hasUndo){
      if(!this.list[this.index - 1] && this.list.length == 1){
        this.reset();
        this.save();
        return
      }
      while(this.list[this.index].content == this.list[this.index - 1].content){
        this.index--;
        if(this.index == 0){
          return this.replace();
        }
      }
      this.replace(--this.index);
    }
  }

  redo(){
    if(this.hasRedo){
      while(this.list[this.index].content == this.list[this.index + 1].content){
        this.index++;
        if(this.index == this.list.length - 1){
          return this.replace();
        }
      }
      this.replace(++this.index);
    }
  }

  replace(){
    const editor = this.editor;
    const roll = this.list[this.index];
    const rng = document.createRange();
    editor.el.innerHTML = roll.content;
    editor.sel.removeAllRanges();
    rng.setStart(roll.adr.startContainer,roll.adr.startOffset);
    rng.setEnd(roll.adr.endContainer,roll.adr.endOffset);
    editor.sel.addRange(rng);
    editor._selectionChange();
    editor._contentChange();
    this.update()
  }

  getRoll(){
    const adr = this.editor.range;
    const content = this.editor.el.innerHTML;
    return {
      content,
      adr
    }
  }

  save(){
    const currentRoll = this.getRoll();
    if(this.list.length && !this.list[this.index].adr.sameRange(currentRoll) 
    && this.list[this.index].content == currentRoll.content){
      return;
    }
    this.list.push(currentRoll);
    if(this.list.length > this.maxCount){
      this.list.shift();
    }
    this.index = this.list.length - 1;
    this.update();
  }

  update(){
    this.hasRedo = !!this.list[this.index + 1];
    this.hasUndo = !!this.list[this.index - 1];
  }

  reset(){
    this.list = [];
    this.index = 0;
    this.hasUndo = false;
    this.hasRedo = false;
  }

}

export default MemoryRoll;