/**
 * 工具方法
*/

/**
 * 是否是dom元素
*/
export function isDom(el){
  return el && typeof(el) === "object" && el.nodeType === 1 && typeof(el?.nodeName) === "string";
}

/**
 * 给定节点other是否存在于源节点origin中
 * @param { Node } origin 源节点
 * @param { Node } other 给定节点
*/
export function contains(origin,other){
  return (origin === other ? false : origin.contains(other));
}

/**
 * 是否是dom节点
*/
export function isNode(node){
  return node && typeof(node) === "object" && node instanceof Node;
}

//获取对应选择器的元素
export function querySelector(qs,container){
  return container?.querySelector(qs)??document.querySelector(qs);
}

/**
 * 删除字符串str的首尾空格
 * @method trim
 * @param { String } str 需要删除首尾空格的字符串
 * @return { String } 删除了首尾的空格后的字符串
 */
export function trim(str){
  return str.replace(/(^[ \t\n\r]+)|([ \t\n\r]+$)/g, '');
}

//防抖
export function debounce(fn,delay = 200){
  let timer;
  return function(){
    const args = arguments;
    if(timer){
      clearTimeout(timer);
    }else{
      timer = setTimeout(()=>{
        fn.apply(this,args);
      },delay)
    }
  }
}

/**
 * 返回祖先元素，最高到content之下
 * @param { Node } node
*/
export function retParentNode(node){
  if(node && node.nodeType === 1 && node.className.indexOf("idiom-content") !== -1) return node;
  let parent = node?.parentNode;
  while(parent){
    if(parent.className.indexOf("idiom-content") === -1 && parent.nodeName !== "BODY"){
      if(parent.nodeName === "P"){
        return parent;
      }
      node = parent;
      parent = parent.parentNode;
      continue;
    }
    return node;
  }
  return null;
}

/** 
 * 检测节点node在父节点中的索引位置， 根据给定的mergeTextNode参数决定是否要合并多个连续的文本节点为一个节点
 * @param { Node } node 需要检测的节点对象
 * @param { Boolean } ignoreTextNode 是否合并多个连续的文本节点为一个节点
 * @return { Number } 该节点在父节点中的位置
*/
export function getNodeIndex(node, ignoreTextNode) {
  var preNode = node,
      i = 0;
  while (preNode.previousSibling) {
    preNode = preNode.previousSibling
    if (ignoreTextNode && preNode.nodeType == 3) {
        if(preNode.nodeType != preNode.nextSibling.nodeType ){
            i++;
        }
        continue;
    }
    i++;
  }
  return i;
}

/***
 * html创建文档碎片
 * @param { String } html
*/
export function createFragment(html){
  if(html && typeof html === "string"){
    const temp = document.createElement("div");
    const frag = document.createDocumentFragment();
    temp.innerHTML = html;
    const childs = temp.childNodes;
    let cd = null;
    for(let i = 0; i < childs.length; i++){
      cd = childs[i];
      frag.appendChild(cd);
      i--;
    }
    return frag;
  }
}

export function isBody(node) {
  return  node && node.nodeType == 1 && node.tagName.toLowerCase() == 'body';
}

/**
 * 根据给定的过滤规则filterFn， 查找符合该过滤规则的node节点的第一个祖先节点，
 * 如果includeSelf的值为true，则查找的起点是给定的节点node， 否则， 起点是node的父节点
 * @method findParent
 * @param { Node } node 需要查找的节点
 * @param { Function } filterFn 自定义的过滤方法。
 * @param { Boolean } includeSelf 查找过程是否包含自身
 * @warning 查找的终点是到body节点为止
 * @remind 自定义的过滤方法filterFn接受一个Node对象作为参数， 该对象代表当前执行检测的祖先节点。 如果该
 *          节点满足过滤条件， 则要求返回true， 这时将直接返回该节点作为findParent()的结果， 否则， 请返回false。
 * @remind 如果includeSelf为true， 则过滤器第一次执行时的参数会是节点本身。
 *          反之， 过滤器第一次执行时的参数将是该节点的父节点。
 * @return { Node | Null } 如果找到符合过滤条件的节点， 就返回该节点， 否则返回NULL
 */
export function findParent(node, filterFn, includeSelf) {
  if (node && !isBody(node)) {
    node = includeSelf ? node : node.parentNode;
    while (node) {
      if (!filterFn || filterFn(node) || isBody(node)) {
          return filterFn && !filterFn(node) && isBody(node) ? null : node;
      }
      node = node.parentNode;
    }
  }
  return null;
}

/**
 * 查找节点node的祖先节点集合， 如果includeSelf的值为true，
 * 则返回的结果集中允许出现当前给定的节点， 否则， 该节点不会出现在其结果集中。
 * @method findParents
 * @param { Node } node 需要查找的节点对象
 * @param { Boolean } includeSelf 查找的结果中是否允许包含当前查找的节点对象
 * @return { Array } 给定节点的祖先节点数组
 */
export function findParents(node, includeSelf, filterFn, closerFirst) {
  var parents = includeSelf && ( filterFn && filterFn(node) || !filterFn ) ? [node] : [];
  const parent = findParent(node, filterFn);
  while (parent) {
    node = parent;
    parents.push(node);
  }
  return closerFirst ? parents : parents.reverse();
}

