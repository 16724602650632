/**
 * 初始化编辑器
*/
import { 
  isDom, 
  isNode,
  createFragment,
  querySelector,
  retParentNode,
  contains, 
} from "./utils";
import punc from "./punc";
import Range from "./Range";
import MemoryRoll from "./undo";
import Vue from "vue";
const vm = new Vue;

class Editor {

  static instance = null;

  //编辑器容器
  el = null;

  //选项
  options = {};

  //选区
  sel = null;

  //区域
  range = null;
  
  //引用尾注序号
  quoteIndex = 1;
  
  //旧的内容
  lastContent = "";

  //渲染::before默认内容的样式
  beforeStyle;
  
  //是否极简模式
  _isMini = false;

  //插入内容是否插入尾注
  _withEndNote = true;

  //插入带标号内容的集合
  quoteStartList = [];

  //插入尾注的集合
  quoteEndList = [];

  cleanInput = false;

  lastTimer = null;

  //输入状态
  inputType = false;

  // with endnote getter
  get withEndNote(){
    return this._withEndNote;
  }

  // with endnote setter
  set withEndNote(condition){
    this._withEndNote = !!condition;
  }

  //isMini getter
  get isMini(){
    return this._isMini;
  }

  //isMini setter
  set isMini(condition){
    this._isMini = !!condition;
    vm.$bus.$emit("modechange");
  }

  constructor(el,options = {}){
    if(!Editor.instance){
      Editor.instance = this;
      //获取对应容器
      this.el = this.getContainer(el);
      this.beforeStyle = document.createElement("style");
      this.options = options;
      this._init();
    }
    return Editor.instance;
  }

  /**
   * 初始化方法
   */
  _init(){
    this._initStyle();
    this.getSelection();
    this.getRange();
    this._initEvent();
    this.memoryRoll = new MemoryRoll;
    this.memoryRoll.editor = this;
    this.memoryRoll.save();
    this.memoryRoll.bindShortKeys();
    setTimeout(()=>{
      vm.$bus.$emit("ready");
    },300);
  }

  //初始化事件绑定
  _initEvent(){
    this.el.addEventListener("focus",this.focus.bind(this));
    this.el.addEventListener("blur",this.blur.bind(this));
    this.el.addEventListener("paste",this.paste.bind(this));
    this.el.addEventListener("keydown",this.keyDownHandler.bind(this));
    this.el.addEventListener("keyup",this.keyUpHandler.bind(this));
    this.el.addEventListener("click",this.clickHandler.bind(this));
    this.el.addEventListener("compositionstart",this.compstartHandler.bind(this));
    this.el.addEventListener("compositionend",this.compendHandler.bind(this));
    const clear = this.el.nextElementSibling;
    clear.addEventListener("click",this.clearHandler.bind(this));
  }

  /**
   * 获取容器
   * @param { HTMLElement | String } el 元素或者选择器
   * @return { HTMLElement } 返回容器元素
  */
  getContainer(el){
    if(!el){
      throw new Error("missing element");
    }
    return (isDom(el) && el) || (typeof(el) === "string" && querySelector(el));
  }

  /**
   * 初始化编辑器样式
  */
  _initStyle(){
    if(!this._isMini){
      this.el.innerHTML = "";
      for(let i = 0; i < 5; i++){
        this.el.innerHTML += "<p style='padding:3px 0;line-height:1.2em;'><br/></p>";
      }
    }else{
      this.el.innerHTML = "<p style='padding:3px 0;line-height:1.2em;'><br/></p>";
    }
    this.beforeStyle.id = "editorPlaceholder";
    this.loadBeforeStyle();
    //此时组件的事件监听还没有执行，此处应该异步
    setTimeout(()=>{
      this._contentChange();
    },300);
  }
  
  /***
   * 展示默认内容时动态获取第一个元素的高度，渲染::before的样式
   */
   loadBeforeStyle(){
    const first = this.el.firstChild;
    if(first){
      let h,pt,pb;
      h = parseInt(window.getComputedStyle(first,null).height);
      pt = parseInt(window.getComputedStyle(first,null).paddingTop);
      pb = parseInt(window.getComputedStyle(first,null).paddingBottom);
      h += pt + pb;
      this.beforeStyle.innerHTML = `.content-default::before{
        height:${h}px;
        line-height:${h}px;
      }`;
      this.el.ownerDocument.head.appendChild(this.beforeStyle);
    }
  }
  
  /**
   * 初始化选区
  */
  getSelection(){
    const doc = document,win = window;
    if(document.activeElement !== this.el){
      this.el.focus();
    }
    if(win.getSelection){
      this.sel = win.getSelection();
    }else{
      this.sel = doc.getSelection();
    }
    this.el.blur();
  }

  /**
   * 初始化range/更新range
  */
  getRange(){
    if(this.sel.rangeCount > 0){
      const range = this.sel.getRangeAt(0);
      this.range = new Range();
      this.range.startContainer = range.startContainer;
      this.range.endContainer = range.endContainer;
      this.range.startOffset = range.startOffset;
      this.range.endOffset = range.endOffset;
      this.range.collapsed = range.collapsed;
    }
  }

  clearHandler(){
    //阻止清空瞬间同时输入
    this.cleanInput = true;
    setTimeout(()=>{
      this.cleanInput = false;
    },500);
  }

  //输入法开始
  compstartHandler(){
    this.inputType = true;
  }

  //输入法结束
  compendHandler(){
    this.inputType = false;
  }

  //focus presets handler
  focus(){
    this.el.parentNode.style.cssText = "border:1px solid rgba(69,135,255,.3);";
  }

  //blur presets handler
  blur(){
    this.el.parentNode.style.cssText = "";
  }

  //paste presets handler
  paste(e){
    const clipboard = e.clipboardData;
    //处理粘贴进来的内容
    let text = clipboard.getData("text/plain");
    let html = clipboard.getData("text/html");
    if(!(text || html)) return e.preventDefault();
    if(html){
      this.getClipboardData();
    }
    // if(text){
    //   text = `<p style="padding:3px 0;line-height:1.2em">${text}</p>`;
    //   clipboard.setData("text/plain",text);
    // }
  }

  //selectionchange handler
  _selectionChange(){
    //当前区域改变,更新 range 区域
    const rng = this.sel?.rangeCount > 0 && this.sel.getRangeAt(0);
    if(!this.range.sameRange(rng)){
      this.getRange();
    }
  }

  //contentchange handler
  _contentChange(){
    //当前内容改变
    if(this.el.innerHTML !== this.lastContent){
      this.lastContent = this.el.innerHTML;
      if(this.isEmptyContent()){
        this.resetQuote();
        vm.$bus.$emit("clearall");
      }
      // this.patchQuoteList();
      vm.$bus.$emit("contentchange");
      this.puncStandardRecommendation();
    }
  }

  //clickhandler
  clickHandler(){
    const rng = this.sel.getRangeAt(0);
    if(!this.isEmptyContent() && !this.range.sameRange(rng)){
      this.getRange();
      vm.$bus.$emit("recommendation");
    }
  }

  //keydown presets handler
  keyDownHandler(e){
    if(this.cleanInput){
      return e.preventDefault();
    }
    e.stopPropagation();
    const key = e.keyCode || e.which;
    const rng = this.range;
    if(key === 8){
      if(rng.collapse && rng.startContainer.className.indexOf("quote-start") !== -1){
        let pre;
        rng.setStartBefore(rng.startContainer);
        this.sel.removeAllRanges();
        pre = rng.cloneRange();
        this.sel.addRange(pre);
        e.preventDefault();
      }
    }
    const keys = {
      //  /*Backspace*/ 8:1, /*Delete*/ 46:1,
      /*Shift*/ 16:1, /*Ctrl*/ 17:1, /*Alt*/ 18:1,
      37:1, 38:1, 39:1, 40:1
    }
    clearTimeout(this.rollTimer);
    if(!keys[key] && !(e.ctrlKey || e.shiftKey || e.altKey || e.metaKey)){
      this.rollTimer = setTimeout(()=>{
        if(this.inputType){
          let interval = setInterval(()=>{
            if(!this.inputType){
              clearInterval(interval);
              this.memoryRoll.save();
            }
          },300);
          return;
        }
        this.memoryRoll.save();
      },200);
    }
  }

  //keyup presets handler
  keyUpHandler(e){
    e.stopPropagation();
    const key = e.keyCode || e.which;
    const rng = this.range;
    if(key === 8){
      if(!this.el.firstChild){
        this.el.innerHTML = `<p style="padding:3px 0;line-height:1.2em;"><br/></p>`;
        vm.$bus.$emit("clearall");
        this._selectionChange();
      }
      //此时range还没更新,先更新区域
      this.getRange();
      const rng = this.range;
      //按删除键时，并且在第一行，编辑内无内容,显示清空
      if(rng.startContainer === this.el.firstChild  && this.isEmptyContent()){
        vm.$bus.$emit("clearall");
        this._selectionChange();
      }
    }
    if((key == 13 || key == 32) && !this.isEmptyContent()){
      vm.$bus.$emit("recommendation");
    }
    if(!this.inputType){
      this._selectionChange();
      this._contentChange();
    }
    vm.$bus.$emit("contentchange");
    clearTimeout(this.lastTimer);
    this.lastTimer = setTimeout(()=>{
      vm.$bus.$emit("recommendation");
    },3000);
  }

  /**
   * 标准推荐格式
   * 中文标号：
   * 句号（。）、问号（？）、叹号（！）、逗号（，）顿号（、）、分号（；）和冒号（：）
   * 引号（“”‘’）、括号〔（）[]{}〕、破折号（——）、省略号（……）、着重号（．）
   * 书名号（《》〈〉）、间隔号（·）、连接号（—）和专名号（____）、分隔号（/）
   * 英文标号：
   * 句点：“.”。问号：“?”。感叹号：“!”。逗号：“,”。冒号：“:”。省略号：“...”。分号：“;”
   * 连字符：“-”。连接号：“–”。破折号：“—”。括号：小括号（圆括号）“()”；中括号“[]”
   * 大括号“{}”。引号：双引号“"”；单引号“'”。缩写及所有格符号：“'”
   * 固定触发，用户输入标点符号（包括上述30种）、空格、换行
   */
  puncStandardRecommendation(){
    const rng = this.range;
    if(rng.collapsed){
      const str = rng.startContainer[rng.startContainer.nodeType == 3 ? "nodeValue" : "innerText"].slice(-1);
      for(let i = 0; i < punc.length; i++){
        if(str == punc[i]){
          vm.$bus.$emit("recommendation");
          return true;
        }
      }
    }
  }

  /***
   * 获取编辑器html内容
   */
  getHtml(){
    return this.el.innerHTML;
  }

  /**
   * 获取编辑器文本内容
  */
  getContent(){
    return this.el.innerText;
  }

  /**
   * 获取剪贴板内容
   */
  getClipboardData(){
    let rng = this.range.cloneRange();
    const pastebin = document.createElement("div");
    pastebin.id = "idiom_pastebin";
    pastebin.style.cssText = `position:absolute;z-index:1000;left:-1000px;top:300px;
    width:500px;height:500px;border:1px solid #eee;padding:5px;background-color:#fff`;
    pastebin.setAttribute("contenteditable",true);
    const fillChar = document.createTextNode("\u200B" + "\u200B");
    pastebin.appendChild(fillChar);
    document.body.appendChild(pastebin);
    this.sel.removeAllRanges();
    rng.selectNodeContents(fillChar,0);
    rng.collapse();
    this.sel.addRange(rng);
    setTimeout(()=>{
      const html = pastebin.innerHTML;
      this.handlePasteFormat(html);
      pastebin.removeChild(fillChar);
      pastebin.parentNode.removeChild(pastebin);
      this._contentChange();
      !this.puncStandardRecommendation() && vm.$bus.$emit("recommendation");
    });
  }

  /**
   * 处理粘贴html内容,只做了简单第一层标签的处理
  */
  handlePasteFormat(html){
    if(html){
      const frag = createFragment(html);
      const childs = frag.childNodes;
      let temp = "";
      for(let i = 0; i < childs.length; i++){
        const text = childs[i][childs[i].nodeType == 1 ? 'innerText' : 'nodeValue'].replace(/[\r\n\t]/g,"");
        if(text.replace(/[\r\n\t\u200b]/g,"")){
          const p = `<p style="padding:3px 0;line-height:1.2em;">${text}</p>`;
          temp += p;
        }
      }
      this.insertHtml(temp);
    }
  }

  /**
   * 编辑器文本内容是否为空
  */
  isEmptyContent(){
    const frag = createFragment(this.el.innerHTML);
    if(frag){
      const childs = frag.childNodes;
      for(let i = 0; i < childs.length; i++){
        const cd = childs[i];
        if(cd.textContent.replace(/\u200b/g,"")){
          return false;
        }
      }
    }
    return true;
  }

  /**
   * 获取正文内容
   * 从编辑器开头开始，到最后一个段落，尾注之前结束
  */
  getMainText(){
    const rng = this.range.cloneRange();
    const first = this.el.firstChild;
    const last = querySelector(".quote-title",this.el);
    if(first){
      rng.setStartBefore(first);
    }
    if(last){
      rng.setEndBefore(last);
    }else{
      this.el.lastChild && rng.setEndBefore(this.el.lastChild);
    }
    return rng.toString();
  }

  /**
   * 获取正文最后一个有内容的段落
  */
  findLastMainTextPara(){
    const childs = this.el.childNodes;
    let cd;
    for(let i = childs.length - 1; i >= 0; i--){
      cd = childs[i];
      if(cd.textContent.replace(/\u200b/,"")){
        return cd;
      }
    }
    if(!cd) return this.el.firstChild;
  }

  /**
   * 插入带标号内容
   * @param { String } content 插入正文的内容
  */
  insertLabeled(content,title){
    if(this.withEndNote){
      const span = document.createElement("span");
      const sup = document.createElement("sup");
      const range = this.range.cloneRange();
      sup.className = "quote-start";
      sup.setAttribute("quote-start-index",this.quoteIndex);
      sup.setAttribute("contenteditable",false);
      sup.innerHTML = "[1]";
      const reg = /[\.,;:?!，。、；：！？]/;
      //标号将拼接在标点之前
      if(reg.test(content.slice(-1))){
        span.innerHTML = `${content.slice(0,content.length-1) + sup.outerHTML + content.slice(-1)}`;
        this.insertHtml(span);
      }else{
        span.innerHTML = content + sup.outerHTML;
        this.insertHtml(span);
      }
      this.quoteStartList.push({
        id : this.quoteIndex,
        el : span,
        text : title
      });
      this.updateQuote();
      this.quoteIndex++;
    }else{
      this.insertHtml(`<span>${content}</span>`);
      this._selectionChange();
      this._contentChange();
    }
  }

  /**
   * 插入的内容引用序号升序排序
  */
  updateQuote(){
    const list = this.el.getElementsByClassName("quote-start");
    for(let i = 0; i < list.length; i++){
      list[i].innerText = `[${i + 1}]`;
    }
  }

  /**
   * 插入尾注
   * @param { String } note 尾注内容
  */
  insertEndNote(note){
    if(this.withEndNote){
      let rng = this.range.cloneRange();
      let starts = this.quoteStartList;
      let ends = this.quoteEndList;
      let title = querySelector(".quote-title",this.el);
      let p = document.createElement("p");
      p.style.cssText = "padding:3px 0;line-height:1.2em;";
      //还没有插入过尾注标题
      if(!title || !title.innerText.replace(/\s[\r\n\t\u200b]/g,"")){
        let last = this.findLastMainTextPara();
        rng.setEndAfter(last);
        rng.collapse();
        this.sel.removeAllRanges();
        this.sel.addRange(rng);
        this.getRange();
        for(let i = 0; i < 3; i++){
          this.insertHtml("<p style='padding:3px 0;line-height:1.2em;'><br/></p>");
        }
        title = document.createElement("p");
        title.className = "quote-title";
        title.style.cssText = "padding:3px 0;line-height:1.2em;";
        title.innerHTML = "引用尾注";
        this.insertHtml(title);
      }
      const startIndex = starts[starts.length-1].id;
      const span = document.createElement("span");
      span.className = "quote-end";
      span.setAttribute("quote-end-index",startIndex);
      span.innerHTML = `[${starts.length}]`;
      p.innerHTML = `${span.outerHTML + (!/\./.test(note.slice(-1)) ? note + "." : note)}`;
      if(!ends.length){
        //还没有任何一条尾注时，直接在上次range的位置插入,上次的位置就是尾注标题的位置
        this.insertHtml(p);
      }else{
        this.sel.removeAllRanges();
        this.sel.addRange(rng);
        //与前面内容相同时
        if(ends.some(e => e.text === note)){
          for(let i = 0; i < ends.length; i++){
            if(ends[i].text === note){
              //此时p应该保持之前的el相同的引用,不应是新建的p
              p = ends[i].el;
              const spans = p.getElementsByClassName("quote-end");
              rng.setEndAfter(spans[spans.length-1]);
              rng.collapse();
              this.getRange();
              this.insertHtml(span);
              break;
            }
          }
        }else{
          //内容不同
          const deff = ends.reduce(
            (prev,cur) => {
              if(!prev.el){
                prev.el = cur.el;
              }
              const prevSpan = prev.el.getElementsByClassName("quote-end")[0];
              const curSpan = cur.el.getElementsByClassName("quote-end")[0];
              const prevIndex = Number(prevSpan.getAttribute("quote-end-index"));
              const curIndex = Number(curSpan.getAttribute("quote-end-index"));
              const max = Math.max(prevIndex,curIndex);
              if(prevIndex === max){
                return prev;
              }
              prev.el = cur.el;
              return prev;
            },{}
          );
          rng.setEndAfter(deff.el);
          rng.collapse();
          this.getRange();
          this.insertHtml(p);
        }
      }
      this.sel.removeAllRanges();
      this.sel.addRange(rng);
      rng.selectNodeContents(starts[starts.length-1].el);
      rng.collapse(); 
      this._selectionChange();
      this._contentChange();
      this.puncStandardRecommendation() && vm.$bus.$emit("recommendation");
      this.quoteEndList.push({
        id : startIndex,
        el : p,
        text : note
      });
    }
  }

  /**
   * 更新引用尾註序号---未完善
  */
  updateEndNode(){
    const list = this.el.getElementsByClassName("quote-end");
    let ends;
    for(let i = 0; i < list.length; i++){
      if(list[i].getElementsByClassName("quote-end").length > 1){
        ends = list[i].getElementsByClassName("quote-end");

      }
      list[i].innerText = `[${i + 1}]`;
    }
  }

  /**
   * 内容改变更新插入内容或者尾注集合---未完善
  */
  patchQuoteList(){
    const starts = this.quoteStartList;
    const ends = this.quoteEndList;
    const temp = [];
    starts.forEach(s => {
      if(!contains(this.el,s.el) || !querySelector(".quote-start",s.el)){
        temp.push(s.id);
      }
    });
    temp.forEach(i => {
      starts.forEach((s,si) => {
        if(i === s.id){
          starts.splice(si,1);
        }
      });
    });
    ends.forEach(e => {
      if(!contains(this.el,e.el) || !querySelector(".quote-end",e.el)){
        temp.push(e.id);
      }
    });
    temp.forEach(i => {
      ends.forEach((e,ei) => {
        if(i === e.id){
          ends.splice(ei,1);
        }
      });
    });
  }

  /**
   * 重置尾注
  */
  resetQuote(){
    this.quoteStartList = [];
    this.quoteEndList = [];
    this.quoteIndex = 1;
  }

  /**
   * 获取光标位置前的段落内容
   * @param { Function } filterFn 选择过滤相应内容的回调
   * @returns { String } recommendContent 内容结果
  */
  getCursorBeforeContent(filterFn) {
    const rng = this.range;
    const parent = retParentNode(rng.startContainer);
    let result = "";
    if(parent){
      const end = rng.endContainer,offset = rng.endOffset;
      const pre = document.createRange();
      pre.setStart(parent,0);
      pre.setEnd(end,offset);
      if(filterFn){
        result = filterFn(pre.cloneContents().textContent);
      }else{
        result = pre.toString();
      }
    }
    return result;
  }

  /**
   * 插入光标位置
   * @param { String | Node } html 插入的html字符串或节点
   * @example
   * |表示光标位置
   * <div>aaa<span>bb|b</span>aaa</div>
   * insertHtml("<p></p>");
   * //<div>aaa<span>bb<p>|</p>b</span>aaa</div>
   * insertHtml("<p>你好</p><p>hello</p>");
   * //<div>aaa<span>bb<p>你好</p><p>hello</p>|b</span>aaa</div>
  */
  insertHtml(html){
    if(!html) return;
    const rng = this.range;
    const pre = rng.cloneRange();
    const end = pre.endContainer,
          endOffset = pre.endOffset;
    let node = isNode(html) ? html : 
    (typeof(html) === "string" && /<[^>]+>/.test(html) ? createFragment(html) : document.createTextNode(html));
    let temp = node.nodeType === 11 ? node.childNodes[node.childNodes.length-1] : null;
    //区域闭合
    if(pre.collapsed){
      //文本节点
      if(end.nodeType === 3){
        //文本内容最后位置
        if(endOffset === end.nodeValue.length){
          let next;
          while(end.nextSibling){
            next = end.nextSibling;
            if(next) break;
          }
          //如果有兄弟节点，移动到下一个节点前
          if(next){
            pre.setEndBefore(next);
            !pre.collapsed && pre.collapse();
            pre.insertNode(node);
            pre.setEndAfter(temp??node);
          }else{
            //在父节点最后插入
            if(end.parentNode){
              end.parentNode.appendChild(node);
              pre.setEndAfter(temp??node); 
            }
          }
        }else{
          if(node.nodeType === 3){
            end.nodeValue = end.nodeValue.substr(0,endOffset) + html + end.nodeValue.substr(endOffset);
            pre.setEnd(end,endOffset+html.length);
          }else{
            pre.insertNode(node);
            pre.setEndAfter(temp??node);
          }
        }
      } //区域是元素节点
      else{
        if(endOffset === end.childNodes.length){
          end.appendChild(node);
          pre.setEndAfter(temp??node);
        }else{
          pre.insertNode(node);
          pre.setEndAfter(temp??node);
        }
      }
      pre.collapse();
      this.sel.removeAllRanges();
      this.sel.addRange(pre);
      this.getRange();
    }else{
      pre.deleteContents();
      pre.insertNode(node);
      pre.setEndAfter(temp??node);
      pre.collapse();
      this.sel.removeAllRanges();
      this.sel.addRange(pre);
      this.getRange();
    }
  }

}

export default Editor;