export const defineSteps = [
  {
    element: "#startTraceSource",
    popover: {
      title: "开始溯源",
      description: "点击开始溯源，溯源内容为左侧文档内容\n",
      position: "right-center",
    },
  },
  {
    element: "#traceList",
    popover: {
      title: "溯源报告",
      description: "已经溯源历史记录\n",
      position: "bottom-center",
    },
  },
  // {
  //   element: "#uploaderPop",
  //   popover: {
  //     title: "文档/图片/视频/音频溯源",
  //     description: "上传Word文档/图片/视频/音频文件，进行溯源\n",
  //     position: "bottom-center",
  //   },
  // },
  // {
  //     element: "#settingTrace",
  //     popover: {
  //       title: "溯源设置",
  //       description: "溯源设置\n",
  //       position: "bottom-center",
  //     },
  //   },
]

export default defineSteps

