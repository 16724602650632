import crewordRequest from "./crewordRequest";
import request from "./request";
//获取自定义核校列表
export function findCustomChecking(data) {
  return crewordRequest({
    url: "/verify/verifyRulesPageList",
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
//新增自定义核校列表
export function addCustomChecking(data) {
  return crewordRequest({
    url: "/verify/verifyRulesAdd",
    method: "POST",
    data,
  });
}
//删除自定义核校列表
export function deleteCustomChecking(data) {
  return crewordRequest({
    url: "/verify/delRules",
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
//修改自定义核校列表
export function editCustomChecking(data) {
  return crewordRequest({
    url: "/verify/updateRules",
    method: "POST",
    data,
  });
}
export function importCustomChecking(data) {
  return crewordRequest({
    url: "/verify/importExcleFile",
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
// 
export function verifyFiles(data) {
  return crewordRequest({
    url: "/verify/verifyFiles",
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
// /verify/verifyText
export function verifyText(data) {
  return crewordRequest({
    url: "/verify/verifyText",
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export function downloadSaveFile(data) {
  return crewordRequest({
    url: '/verify/downloadSaveFile',
    method: 'post',
    responseType: 'blob',
    data,
  });
}

export function saveVcResult(data) {
  return crewordRequest({
    url: "/verify/saveVcResult",
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
export function VerifyVerifyRulesPageList(data) {
  return crewordRequest({
    url: "/vcrecord/pageList",
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
//获取白名单规则列表
export function findCheckingWhite(data) {
  return crewordRequest({
    url: "/verify/verifyWhiteListPage",
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
//加入白名单
export function addCheckingWhite(data) {
  return crewordRequest({
    url: "/verify/verifyWhiteListAdd",
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
//移出白名单
export function deleteCheckingWhite(data) {
  return crewordRequest({
    url: "/verify/delVerifyWhiteList",
    method: "DELETE",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
//功能描述
export function startCheckingContent(data) {
  return crewordRequest({
    url: "/verify/verifyStart",
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
//获取核校列表
export function getCheckingList() {
  return crewordRequest({
    url: "/verify/verifyResult",
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
// 获取规则信息
export function getCheckingInfo() {
  return crewordRequest({
    url: "/verify/rulesInfo",
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export function isFirstLogin(data) {
  return request({
    url: "/user/firstLogin",
    method: "GET",
    params: data,
  });
}

export function advertisingChecking(data) {
  return crewordRequest({
    url: "/verify/adErrorSetStart",
    method: "POST",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
