/**
 * Range
*/

class Range {

  constructor(){
    this.startContainer = this.startOffset =
    this.endContainer = this.endOffset = null;
    this.document = document;
    this.collapsed = true;
  }

  //克隆一个相同位置的range
  cloneRange(){
    const pre = document.createRange();
    pre.setStart(this.startContainer,this.startOffset);
    pre.setEnd(this.endContainer,this.endOffset);
    return pre;
  }

  //是否相同位置的range
  sameRange(target){
    if((this.startContainer === target.startContainer && this.startOffset === target.startOffset)
    && this.endContainer === target.endContainer && this.endOffset === target.endOffset){
      return true;
    }
    return false;
  }

}

export default Range;