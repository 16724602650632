<template>
  <div class="idiom-editor">
    <div class="idiom-editor-wrap">
      <div class="idiom-editor-wrap-background">
        <div contenteditable="true" spellcheck="false" :class="['content','idiom-content',
        {'content-default':isEmpty,'content-minimalist':minimalist}]" ref="idiomEditor"
        @click="isEmpty = false">
        </div>
        <i class="idiom-editor-clear" @click="clearContent" v-show="!isEmpty"></i>
        <i class="idiom-editor-copy" @click="copy" v-show="!isEmpty"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "/src/idiom-editor/Editor";
import { settings } from "api/service";
export default {
  name : "IdiomEditor",
  data(){
    return {
      content : "",
      editor : null,
      isEmpty : false,
      //是否极简模式
      minimalist : false,
      messageSuccess : false,
      lastRecom : ""
    }
  },
  methods : {
    clearContent(){
      this.editor._initStyle();
      this.editor.getRange();
    },
    copy(){
      //执行复制
		  let domUrl = document.createElement("input");
		  domUrl.value = this.editor.getContent();
		  domUrl.id = "creatDom";
		  document.body.appendChild(domUrl);
		  domUrl.select(); 
		  document.execCommand("Copy"); 
		  let creatDom = document.getElementById("creatDom");
		  creatDom.parentNode.removeChild(creatDom);
		  this.$message({
		    type:"success",
		    message:"复制成功！",
        customClass : "short-message",
        duration : 500
		  });
    },
    //获取用户设置
    async getSettings(){
      const res = await settings();
      if(res.code == 200){
        const startEndnotes = res.data?.recStartEndnotes;
        if(startEndnotes == 1){
          this.editor.withEndNote = true;
        }else{
          this.editor.withEndNote = false;
        }
      }
    }
  },
  mounted(){
    this.editor = new Editor(".content");
    this.getSettings();
    if(!this.editor.el){
      //挂载后没有容器，重新获取容器并初始化;
      this.editor.el = this.editor.getContainer(".content");
      this.editor._init();
    }
    this.content = this.editor.getHtml();
    /**
     * 编辑器内容改变
     */
    this.$bus.$on("contentchange",() => {
      this.content = this.editor.getContent().replace(/[\n\r\t\s]/g,"");
      this.isEmpty = this.editor.isEmptyContent();
      this.$emit("resize");
    });
    this.$bus.$on("recommendation",() => {
      let result = this.editor.getCursorBeforeContent(
        recommend => recommend.replace(/\[(\d+)\]/g,"").replace(/[\n\r\t\s]/g,"")
      );
      if(result && result.length >= 2 && this.lastRecom != result){
        this.lastRecom = result;
        this.$emit("change",this.isEmpty?"clean":result);
      }
    })
    //监听编辑器模式改变
    this.$bus.$on("modechange",()=>{
      this.minimalist = this.editor.isMini;
    });
    //监听初始化或清空内容
    this.$bus.$on("clearall",() => {
      this.isEmpty = true;
      this.lastRecom = "";
      this.$emit("change","clean");
    });
    //撤销
    window.idiomTextUndo = () => {
      this.editor.memoryRoll.undo();
    }
    //重做
    window.idiomTextRedo = () => {
      this.editor.memoryRoll.redo();
    }
    //滑词
    window.slipperyWord = content => {
      this.editor.el.innerHTML = "<p><br/></p>";
      this.editor.el.focus();
      this.editor.getRange(); //更新区域
      this.editor.insertHtml(content);
      this.editor.resetQuote();
      this.$bus.$emit("contentchange");
      this.$bus.$emit("recommendation");
    }
  },
  beforeDestroy(){
    this.$bus.$off("contentchange");
    this.$bus.$off("modechange");
    this.$bus.$off("clearall");
    this.editor.el = null;
  }
}
</script>

<style lang="scss" scoped>
.idiom-editor{
  padding-top:10px;
  &.idiom-default{
    .idiom-editor-wrap{
      border-bottom:none;
    }
  }
  &-wrap{
    position:relative;
    padding-bottom:20px;
    border-bottom:1px solid #F7F7F7;
    &-background{
      padding:20px 10px 5px 10px;
      border-radius:5px;
      border:1px solid #F0F0F0;
      background-color:#FAFAFB;
      box-sizing:border-box;
      .content{
        min-height:120px;
        max-height:200px;
        padding:5px;
        margin-top:5px;
        font-size:14px;
        font-family:"宋体";
        color:#000;
        overflow-y:scroll;
        box-sizing:border-box;
        &:focus{
          outline:none;
        }
        &::before{
          content:"请输入或粘贴文字，例如 “解放思想”、“不忘初心”...";
          position:absolute;
          display:none;
          font-weight:500;
          color:#999;
          cursor:text;
        }
        &::-webkit-scrollbar {
          width:3px;
          height: 8px;
        }
        &::-webkit-scrollbar-track {
          display: none;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #ff6900 .5;
        }
        &.content-default{
          &::before{
            display:block;
          }
        }
        &.content-minimalist{
          height:25px;
          overflow-y:hidden;
          overflow-x:scroll;
        }
      }
      .idiom-editor-clear,.idiom-editor-copy{
        @include noselect;
        position:absolute;
        right:5px;
        top:6px;
        width:16px;
        height:16px;
        &::before{
          content:"";
          position:absolute;
          width:100%;
          height:100%;
          background:url("~assets/images/idiom/edit-clear.png") no-repeat;
          background-size:100%;
          cursor:pointer;
        }
      }
      .idiom-editor-copy{
        right:30px;
        &::before{
          background:url("~assets/images/goldenSentence/subTitle-icon.png") no-repeat;
          background-size:100%;
        }
      }
    }
  }
}
</style>