<template>
  <div style="width: 100%">
    <div class="office-tracesource" v-show="!isDetails">
      <OfficeNav>
        <div class="left">
          <el-tooltip
            content="对文档的篇,段,句追溯原创出处,统计原创率,需要根据文档字数扣减字符包字符"
            placement="bottom"
            effect="light"
          >
            <div class="start" @click="handleStartSource">
              <img
                :src="require('assets/images/tracesource-start.png')"
                alt=""
              />
              <span>开始溯源</span>
            </div>
          </el-tooltip>
          <!-- <div class="file" id="uploaderPop" @click="uploaderPop">
          <img :src="require('assets/images/tracesource-upload.png')" alt="" />
          <span>文档/图片/视频/音频溯源</span>
          </div> -->
          <el-tooltip
            content="溯源后标注原创来源的报告"
            placement="bottom"
            effect="light"
          >
            <div class="start" @click="sourceReport">
              <img
                :src="require('assets/images/tracesource-report.png')"
                alt=""
              />
              <span>溯源报告</span>
            </div>
          </el-tooltip>
          <!-- <el-tooltip
            content="设置溯源样式与扣减字符程序"
            placement="bottom"
            effect="light"
          >
            <div class="sets" @click="handleSetupOpen">
              <img :src="require('assets/images/tracesource-set.png')" alt="" />
              <span>溯源设置</span>
            </div>
          </el-tooltip> -->
          <el-tooltip
            content="可以用于溯源,数据分析等功能支付"
            placement="bottom"
            effect="light"
          >
            <div class="start">
              <img :src="require('assets/images/t.png')" alt="" />
              <span>字符余额: {{ character }}</span>
            </div>
          </el-tooltip>
        </div>

        <div class="progress">
          溯源进度:
          <el-progress
            v-show="isShowProgress"
            :stroke-width="10"
            :show-text="false"
            :percentage="progress"
          ></el-progress>
        </div>
      </OfficeNav>
      <IdiomEditor ref="idiomEditor" @change="contentChange" />
      <div class="arousel" v-if="!firstTraced">
        <div class="recommend_box">
          <div class="top">
            <div class="txt" @click="handleClickDep">
              <img
                style="vertical-align: middle"
                :src="require('assets/images/base_map2.png')"
                alt=""
              />
              <span>溯源·第一议题学习专栏</span>
            </div>
            <div class="txt1" @click="goDep">查看更多>></div>
          </div>

          <div class="bottom">
            <div
              v-for="(v, index) in article"
              :key="index"
              class="article_item"
              @click="openArticle(v)"
            >
              <span>{{ index + 1 }}</span> {{ v.fileName }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="office-tracesource-table"
        ref="tablewrap"
        v-if="firstTraced && traceReportList.length != 0"
      >
        <div class="search_nav">
          <div class="sort">
            <div
              class="sort_item"
              v-for="(item, index) in sortArr"
              @click="sortClick(item, index)"
              :key="item.id"
            >
              <span :class="{ 'is-active': currentSortIndex == index }">{{
                item.title
              }}</span>
              <i
                class="right-item-icon"
                :class="item.sort == true ? 'up' : 'down'"
              ></i>
            </div>
          </div>

          <div class="search-left">
            <el-input
              placeholder="请输入文件名搜索"
              v-model="searchKey"
            ></el-input>
            <div class="search-btn" @click="searchKeyFile">
              <!-- <img :src="require('assets/images/included_search.png')" alt="" /> -->
              <i class="el-icon-search"></i>
            </div>
          </div>
        </div>
        <div class="traceReportList">
          <div
            class="trace_item"
            v-for="item in traceReportList"
            :key="item.id"
          >
            <img
              :src="require(`@/assets/images/disk/Icons/${item.num}.png`)"
              alt=""
            />
            <div class="info">
              <div class="title">{{ item.fileName }}</div>
              <div class="other">
                <div class="evaluate-wrap">
                  <div
                    class="nothing"
                    v-if="item.status === 0 && !item.evaluation"
                  >
                    溯源评价:未溯源
                  </div>
                  <div class="failed" v-if="item.status === 3">
                    溯源评价:{{ item.status_remark }}
                  </div>
                  <div
                    v-if="
                      (item.reportType == 1 || item.reportType == 0) &&
                      item.evaluation
                    "
                    class="original"
                  >
                    <span>溯源评价:</span>
                    <img
                      v-if="item.level == 'A+'"
                      class="levelIcon"
                      :src="
                        item.level == 'A+'
                          ? require('assets/images/A+.png')
                          : ''
                      "
                      alt=""
                    />
                    <img
                      v-if="item.level == 'A'"
                      class="levelIcon"
                      :src="
                        item.level == 'A' ? require('assets/images/A.png') : ''
                      "
                      alt=""
                    />
                    <img
                      v-if="item.level == 'B+'"
                      class="levelIcon"
                      :src="
                        item.level == 'B+'
                          ? require('assets/images/B+.png')
                          : ''
                      "
                      alt=""
                    />
                    <img
                      v-if="item.level == 'B'"
                      class="levelIcon"
                      :src="
                        item.level == 'B' ? require('assets/images/B.png') : ''
                      "
                      alt=""
                    />
                    <img
                      v-if="item.level == 'C'"
                      class="levelIcon"
                      :src="
                        item.level == 'C' ? require('assets/images/c.png') : ''
                      "
                      alt=""
                    />
                    <span class="txt">原创率{{ item.evaluation }}%</span>
                  </div>
                  <div
                    v-if="item.reportType > 1 && item.evaluation"
                    class="homology"
                  >
                    溯源评价:
                    <img
                      v-if="item.evaluation == '有同源'"
                      :src="require('assets/images/homology-y.png')"
                      alt=""
                    />
                    <img
                      v-else
                      :src="require('assets/images/homology-n.png')"
                      alt=""
                    />
                    <span
                      :class="[
                        'txt',
                        item.evaluation == '有同源'
                          ? 'is-homology'
                          : item.evaluation == '无同源'
                          ? 'no-homology'
                          : '',
                      ]"
                    >
                      {{ item.evaluation }}
                    </span>
                  </div>
                </div>
                <div class="time">溯源时间:{{ item.createTime }}</div>
              </div>
            </div>
            <div class="more">
              <el-popover
                popper-class="option-popper"
                placement="bottom"
                trigger="click"
              >
                <div class="option_item" @click.stop="viewDetail(item)">
                  查看详情
                </div>

                <div
                  class="option_item"
                  :data-subitem="item.reportWordUrl || item.reportUrl"
                  title="溯源报告"
                  v-if="completeStatus(item.status)"
                  @click="sourceReportOption(item)"
                >
                  溯源报告
                </div>
                <div
                  class="option_item"
                  :data-subitem="item.url"
                  title="源文件"
                  v-if="completeStatus(item.status)"
                  @click="sourceFile(item)"
                >
                  源文件
                </div>
                <div class="option_item" @click="delHandler(item.id)">删除</div>
                <div
                  v-if="item.originalIds"
                  title="在线授权"
                  :class="[
                    'authorize',
                    'option_item',
                    item.originalIds && item.originalIds != ''
                      ? 'is-blue'
                      : 'is-gray',
                  ]"
                  @click="showAuthorize(item.originalIds, item.id, item)"
                >
                  {{
                    item.originalIds && item.originalIds != ""
                      ? "可在线授权"
                      : "无在线授权"
                  }}
                </div>
                <div
                  class="option_item"
                  title="分享"
                  v-if="completeStatus(item.status)"
                  @click.stop="
                    QrCodeShow(
                      item.reportWordUrl ? item.reportWordUrl : item.url,
                      item.fileName
                    )
                  "
                >
                  分享
                </div>

                <img
                  slot="reference"
                  :src="require('assets/images/headers/more.png')"
                  alt=""
                />
              </el-popover>
            </div>
            <div
              :class="[
                'states',
                item.status === 0
                  ? 'orange'
                  : item.status === 1
                  ? 'blue'
                  : item.status === 2
                  ? 'green'
                  : 'gray',
              ]"
            >
              {{
                item.status === 0
                  ? "未溯源"
                  : item.status === 1
                  ? "溯源中"
                  : item.status === 2
                  ? "完成"
                  : "失败"
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="office-tracesource-footer"
        v-if="firstTraced && traceReportList.length != 0"
      >
        <el-pagination
          layout="total, prev, pager, next, jumper"
          :pager-count="5"
          :current-page="pageNum"
          :page-size="pageSize"
          @current-change="currentChange"
          :total="traceReportTotal"
        >
        </el-pagination>
      </div>
      <div v-if="traceReportList.length == 0" class="kong">
        <img :src="require('assets/images/kong.png')" />
        <div>暂无溯源报告</div>
      </div>
      <!-- 链接二维码弹窗 -->
      <el-dialog
        title="二维码"
        :close-on-click-modal="false"
        :visible.sync="QrCodeVisible"
        @close="handleCloseQrcode"
        class="qrcode"
      >
        <div class="content">
          <div class="notes">分享二维码</div>
          <div class="imgBox">
            <div ref="qrcode_box"></div>
          </div>
          <!-- <el-button type="primary" @click="submitOrder">提交订单</el-button> -->
        </div>
      </el-dialog>
      <!--溯源文件上传附件弹出框 -->
      <el-dialog
        v-dialogDrag
        title="文档/图片/视频/音频溯源"
        :visible.sync="uploadVisible"
        class="upload"
      >
        <div class="content-text">
          溯源是根据文档的MD5值和图片、视频与音频的哈希值进行追根溯源。单篇文档需支付全文对应的字符，单张图片需支付1000字符，单个视频需支付3000字符，单个音频需支付3000字符。
        </div>
        <!--上传器-->
        <UploadBigFile
          ref="uploader"
          :columnType="columnType"
          :fileType="fileType"
          @onFileSuccess="onFileSuccess"
          @onFileError="onFileError"
        >
        </UploadBigFile>
      </el-dialog>
      <!-- 设置中心 -->
      <el-dialog
        title="设置中心"
        :visible.sync="dialogsetup"
        class="setupCenter"
      >
        <div class="content">
          <div class="content-title">快捷支付字符</div>
          <el-radio v-model="payType" label="1">快捷支付字符</el-radio>
          <el-radio v-model="payType" label="2">手动确认扣减</el-radio>
        </div>
        <div class="contentStyle">
          <div class="contentStyle-title">内容溯源样式</div>
          <div class="contentStyle-item">
            <div class="contentStyle-item-label">重复字段标注：</div>
            <div class="contentStyle-item-radio">
              <el-radio v-model="fieldStyle" label="1">红色字体</el-radio>
              <el-radio v-model="fieldStyle" label="2">蓝色字体</el-radio>
              <el-radio v-model="fieldStyle" label="3">下划线</el-radio>
            </div>
          </div>
          <!-- <div class="contentStyle-item">
          <div class="contentStyle-item-label">来源出处标注：</div>
          <div class="contentStyle-item-radio">
            <el-radio v-model="sourceLocation" label="1">脚注</el-radio>
            <el-radio v-model="sourceLocation" label="2">尾注</el-radio>
          </div>
        </div> -->
        </div>
        <div class="footer">
          <div class="footer-btn sure" @click="handleSetupSure">确定</div>
          <div class="footer-btn" @click="dialogsetup = false">取消</div>
        </div>
      </el-dialog>
      <!-- 提示弹窗 -->
      <el-dialog title="提示" :visible.sync="dialogTips" class="tips">
        <div class="content" v-if="curCharacter >= wordCount">
          <div class="content-text">
            本次溯源将扣减<span>{{ wordCount }}</span
            >个字符，字符包现有<span>{{ curCharacter }}</span
            >个字符，该字符将支付给重复字段的原创作者。
          </div>
        </div>
        <div
          v-else
          class="content"
          :class="curCharacter < wordCount ? 'active' : ''"
        >
          <i class="content-icon"></i>
          <div class="content-text">
            本次溯源将扣减
            <span>{{ wordCount }}</span
            >个字符，字符包现有 <span>{{ curCharacter }}</span
            >个字符，<span>已不足。</span>
          </div>
        </div>
        <div class="footer">
          <div class="footer-btn" @click="sureSubmit">
            {{ curCharacter >= wordCount ? "确定" : "取消" }}
          </div>
          <div
            v-if="curCharacter >= wordCount"
            class="footer-btn"
            @click="handleQuickPay"
          >
            字符包快捷支付
          </div>
          <div v-else class="footer-btn" @click="buyCharPack">购买字符包</div>
        </div>
      </el-dialog>
      <!-- 详细信息 -->
      <el-dialog
        title="详细信息"
        width="90%"
        :visible.sync="infoFilesPopup"
        class="addFil"
      >
        <div class="box">
          <span class="boxSpan">文件名：</span>&nbsp;&nbsp;&nbsp;
          <span> {{ valInfo.fileName }}</span>
          <!-- <img
          @click="downloadFil"
          class="download"
          :src="require('assets/images/info/download.png')"
          alt=""
        /> -->
        </div>
        <div class="box">
          <span class="boxSpan">类型：</span>&nbsp;&nbsp;&nbsp;
          <span>
            {{
              valInfo.reportType == 1
                ? "文档"
                : valInfo.reportType == 2
                ? "图片"
                : valInfo.reportType == 3
                ? "视频"
                : valInfo.reportType == 4
                ? "音频"
                : valInfo.reportType == 0
                ? "富文本"
                : "-"
            }}
          </span>
        </div>
        <div class="box">
          <span class="boxSpan">数据大小：</span>&nbsp;&nbsp;&nbsp;
          <span>{{ valInfo.fs }}</span>
        </div>
        <div class="box">
          <span class="boxSpan">字符：</span>&nbsp;&nbsp;&nbsp;
          <span>{{ valInfo.price }}</span>
        </div>
      </el-dialog>
      <!--数据源时间选择-->
      <el-dialog
        :modal="false"
        :close-on-click-modal="false"
        title="溯源截止日期（根据选择日期内数据进行溯源）"
        :visible.sync="sourceTimespan"
        class="source-timespan"
      >
        <div class="content">
          <div class="time-wrap">
            <!-- <div class="date-wrap">
            <span class="title">开始时间</span>
            <el-date-picker
              v-model="sourceStartTime"
              type="date"
              placeholder="不限时间"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
          <span class="bar">—</span> -->
            <div class="date-wrap">
              <!-- <span class="title">结束时间</span> -->
              <el-date-picker
                v-model="sourceEndTime"
                type="date"
                placeholder="结束时间"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="footer">
          <span class="start" @click="startTraceSource">开始溯源</span>
          <span class="cancel" @click="sourceTimespan = false">取消溯源</span>
        </div>
      </el-dialog>
      <TraceSourceAuthorizationList
        ref="traceAuthorizeList"
        :show-authorize-list="showAuthorizeList"
        :sourecFileType="sourecFileType"
        @showAuthorizeDetail="showAuthorizeInfo"
        @close="showAuthorizeList = false"
      />
      <TraceSourceAuthorizationInfo ref="traceAuthorize" />
    </div>
    <el-dialog title="提示" :visible.sync="buyIngDialog" width="50%">
      <span>您已购买此文章</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openWorks">打 开</el-button>
        <el-button type="primary" @click="downLoadWroks">下 载</el-button>
      </span>
    </el-dialog>
    <div class="dataAnalysisDetails" v-show="isDetails">
      <dataAnalysisDetails ref="dataAnalysisDetails" @backClick="backClick" />
    </div>
  </div>
</template>

<script>
import OfficeNav from "components/office-nav/OfficeNav";
import { depthLearnList } from "api/idiom";
import QrCode from "qrcodejs2";
import {
  findTraceReport,
  delHisTraceSource,
  delTracesourceQueue,
  getTraceSetup,
  postTraceSetup,
  pluginTraceSource,
  getTraceSourceResult,
  traceSource,
  checkInterestsNumber,
} from "api/traceTheSource";
import IdiomEditor from "components/idiom/edit/IdiomEditor";
import { getToken } from "utils/auth";
import { previewUrl } from "api/common";
import UploadBigFile from "components/disk/TraceTheSourceUpload.vue";
import TraceSourceAuthorizationList from "components/traceSource/TraceSourceAuthorizationList";
import TraceSourceAuthorizationInfo from "components/traceSource/TraceSourceAuthorizationInfo";
import Pager from "components/idiom/pager/IdiomPager";
import defineSteps from "./driver/driver.js";
import { isFirstLogin } from "api/checking";
import dataAnalysisDetails from "views/dataAnalysis/dataAnalysisDetails.vue";
export default {
  name: "OfficeTraceSource",
  data() {
    return {
      sourecFileType: 0,
      buyIngDialog: false,
      isDetails: false,
      // sort: true,
      isShowProgress: true,
      //字符余额
      character: this.$store.state.user_info?.character ?? 0,
      //历史溯源报告表格数据
      traceReportList: [],
      selectDate: "", //日期选择v-model
      searchKey: "", //关键字查询v-model
      traceReportTotal: 0, //总条数
      //当前页
      pageNum: 1,
      //一页显示的大小
      pageSize: 10,
      //数据总数
      // total: 0,
      //总页数
      pageCount: 1,
      //默认服务器路径
      basePath: "http://fafs.antiplagiarize.com/",
      fullPreviewPdf: "",
      // 分享二维码弹窗
      QrCodeVisible: false,
      qr: "",
      //显示可在线授权列表
      showAuthorizeList: false,
      //下载子菜单索引
      subIndex: -1,
      //表格高度
      tableHeight: null,
      //上传文件类型
      fileType: 0,
      //接口上传类型 -- 4-溯源
      columnType: 4,
      //记录上传文件数量
      fileList: [],
      //控制上传弹窗显示
      uploadVisible: false,
      //显示设置中心
      dialogsetup: false,
      //溯源成功提示
      dialogSwitch: false,
      //字符支付方式 手动、快捷
      payType: "",
      //内容溯源样式
      fieldStyle: "",
      //溯源设置id
      setUpId: null,
      //字符扣减弹窗
      dialogTips: false,
      //当前字符数
      curCharacter: 0,
      //扣减字符数
      wordCount: 0,
      //溯源文件地址
      srcFileUrl: "",
      //溯源文件名
      srcFileName: "",
      //详情弹窗
      infoFilesPopup: false,
      //详情数据
      valInfo: {},
      //溯源进度
      progress: 0,
      //取消加载屏
      noNeedLoading: false,
      contentLeft: [
        {
          id: 1,
          title: "溯源是什么",
        },
        {
          id: 2,
          title: "溯源有什么用",
        },
      ],
      contentRight: [
        {
          id: 1,
          title: "对文本的追根溯源，探寻文本的最早出处",
        },
        {
          id: 2,
          title: `迅速知悉文稿中那些是旧提法？哪些是新提法。\n
          快速了解同一文本的语境怎么样？同一语境下的代表性作者、作品有哪些？`,
        },
      ],

      sortArr: [
        //排序数组
        {
          title: "溯源时间",
          sort: false, //true 升序 false降序
          id: 1,
        },
        {
          title: "原创率",
          sort: true,
          id: 2,
        },
      ],

      sortOrder: "2",
      sort: "1",

      currentSortIndex: 0,
      firstTraced: false,
      //数据源时间范围选择
      sourceTimespan: false,
      //数据源开始时间
      sourceStartTime: "",
      //数据源结束时间
      sourceEndTime: this.format(new Date()),

      options: [
        {
          value: "1",
          label: "全文",
        },
        {
          value: "2",
          label: "作者",
        },
        {
          value: "3",
          label: "标题",
        },
        {
          value: "4",
          label: "来源",
        },
      ],
      value: "1",
      article: [],
      search: "",
      isShowHistoryList: false,
      historyList: [], //历史推荐列表
      userId: this.$store.state.user_info && this.$store.state.user_info.id, //用户id
      Editorhtml: "",
    };
  },
  methods: {
    goDep() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.$router.push({
        name: "DepthLearn",
      });
    },
    async getDepthLearnList() {
      const form = new FormData();
      form.append("page", 1);
      form.append("limit", 10);

      const res = await depthLearnList(form);
      if (res.code == 200) {
        this.article = res.data.data;
      }
    },
    // 发送溯源内容
    async sureTraceSource() {
      this.progress = 0;
      this.$message({
        type: "success",
        message: "开始溯源",
      });
      const data = {
        platform: 1,
        wordContent: this.Editorhtml,
        userId: this.userId,
        traceModel: 1,
        fileName: "未命名",
      };
      let resultId;
      const res = await traceSource(data);
      if (res.code == 200) {
        resultId = res.data;
      }
      setTimeout(() => {
        this.getTraceSourceResultClick(resultId);
      }, 2000);
    },
    getTraceSourceResultClick(id) {
      //获取溯源结果
      // this.source_timer = setInterval(() => {}, 2000);
      const params = { id };
      getTraceSourceResult(params)
        .then((res) => {
          this.progress = 100;
          this.gettraceList();
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.name + e.message,
          });
        });
    },
    //是否显示引导
    async isShowDriver() {
      // console.log(this.$store.state.user_info);
      const data = {
        // userId:this.$store.state.user_info.id,
        pageName: "插件版溯源",
      };
      // console.log(data);
      const res = await isFirstLogin(data);
      // console.log(res.data);
      if (res.data) {
        this.$driver.defineSteps(defineSteps);
        this.$driver.start();
      }
    },
    //点击开始溯源
    handleStartSource() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      this.sourceTimespan = true;
      // window.ShowDownLoadTips();
    },
    backClick() {
      this.isDetails = false;
    },
    navInfoCompile(key) {
      //将搜索记录添加到历史列表中
      if (this.historyList.indexOf(key) == -1) {
        const historyList = localStorage.getItem("historyList");
        if (historyList) {
          this.historyList = JSON.parse(historyList);
          if (this.historyList.length >= 10) {
            this.historyList.pop();
          }
          this.historyList.unshift(this.search);
          localStorage.setItem("historyList", JSON.stringify(this.historyList));
        } else {
          this.historyList = [];
          this.historyList.push(this.search);
          localStorage.setItem("historyList", JSON.stringify(this.historyList));
        }
      }
      this.$bus.$emit("changeNavIndex", "文库");
      this.$router.push({
        name: "InfoCompileIndex",
        query: {
          key,
          value: this.value,
        },
      });
    },
    openArticle(val) {
      previewUrl({
        url: this.$store.state.onlineBasePath + val.traceabilityWordPath,
      }).then((res) => {
        window.open(res.data);
      });
      // const loading = this.$loading({
      //   lock: true,
      //   text: "加载中",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.1)",
      // });
      // const suffix = val.traceabilityWordPath.substr(
      //   val.traceabilityWordPath.lastIndexOf(".")
      // );
      // console.log(suffix);
      // try {
      //   // eslint-disable-next-line
      //   OpenDocument2(
      //     val.fileName + suffix,
      //     this.$store.state.onlineBasePath + val.traceabilityWordPath
      //   );
      // } catch (e) {
      //   console.log(e);
      // }

      // loading.close();
    },

    //开始溯源
    startTraceSource() {
      this.firstTraced = true;
      this.sourceTimespan = false;
      this.isShowProgress = true;
      this.$bus.$emit("recommendation");
      this.sureTraceSource();
      this.sort = "1";
      this.sortOrder = "2";
      this.$set(this.sortArr[0], "sort", false);
      this.currentSortIndex = 0;

      try {
        //eslint-disable-next-line
        StartTraceSource();
      } catch (e) {
        console.log(e);
      }
    },
    testStartTraceSource() {
      this.firstTraced = true;
      this.sourceTimespan = false;
      try {
        window.getWordSource(
          "group1/M00/07/7F/L2rchmK4Q3GALhSdAAArtskr0xM99.docx",
          11190,
          "入党个人简历格式.docx"
        );
      } catch (e) {
        console.log(e);
      }
    },
    //溯源报告
    sourceReport() {
      // if (!getToken("auth_token")) {
      //   this.$message({
      //     message: "请先登录！",
      //     duration: 1500,
      //   });

      //   return window.loginFun();
      // }
      this.firstTraced = true;
      this.gettraceList();
    },
    // 获取字符数量
    async getFontNumber() {
      const data = {
        deductNum: this.curCharacter,
        type: 2,
      };
      const res = await checkInterestsNumber(data);
      if (res.code === 200 || res.code === 7002) {
        this.curCharacter = res.data;
        if (this.payType == 1) {
          if (this.curCharacter < this.wordCount) {
            this.dialogTips = true;
          } else {
            this.pluginTraceSource();
          }
        } else {
          this.dialogTips = true;
        }
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    //插件溯源
    async pluginTraceSource() {
      const data = {
        wordUrl: this.srcFileUrl,
        fileWords: this.wordCount,
        fileName: this.srcFileName,
        startDate: this.sourceStartTime,
        endDate: this.sourceEndTime,
      };
      const res = await pluginTraceSource(data);
      if (res.code == 200) {
        this.sourceResultProgress();
        this.getTraceSourceResult(res.data);
        this.noNeedLoading = true;
        this.gettraceList(() => {
          this.noNeedLoading = false;
        });
      }
    },
    //溯源进度条
    sourceResultProgress() {
      let dis = 0;
      let timer1, timer2, timer3;
      this.progress = dis;
      setTimeout(() => {
        timer1 = setInterval(() => {
          if (dis >= 50) {
            clearInterval(timer1);
            timer2 = setInterval(() => {
              dis += Math.floor(Math.random() * 3 + 1);
              if (this.progress == 100) {
                clearInterval(timer1);
                clearInterval(timer2);
                clearInterval(timer3);
                return;
              }
              this.progress = dis;

              if (dis >= 80) {
                clearInterval(timer2);
              }
            }, 200);
          }
          dis += 3;
          if (this.progress == 100) {
            clearInterval(timer1);
            clearInterval(timer2);
            clearInterval(timer3);
            return;
          }
          this.progress = dis;
        }, 100);
        timer3 = setInterval(() => {
          if (this.progress == 100) {
            clearInterval(timer1);
            clearInterval(timer2);
            clearInterval(timer3);
          }
        }, 50);
      }, 500);
    },
    //获取溯源结果
    getTraceSourceResult(id) {
      const params = { id };
      getTraceSourceResult(params)
        .then((res) => {
          if (res.code == 200) {
            // this.progress = +res.data.progress ? +res.data.progress : 0;
            this.noNeedLoading = true;
            this.gettraceList(() => {
              this.noNeedLoading = false;
            });
            try {
              this.progress = 100;
              //eslint-disable-next-line
              OpenDocument2(
                "溯源报告-" + res.data.soleCode + ".docx",
                this.$store.state.onlineBasePath + res.data.reportWordUrl
              );
            } catch (e) {
              console.log(e);
            }
          } else if (res.code == 8001) {
            // this.progress = +res.data.progress ? +res.data.progress : 0;
            setTimeout(() => {
              this.getTraceSourceResult(id);
            }, 2000);
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.name + e.message,
          });
        });
    },
    //确定
    sureSubmit() {
      if (this.curCharacter < this.wordCount) {
        this.dialogTips = false;
        return;
      }
      this.pluginTraceSource();
      this.dialogTips = false;
    },
    //字符快捷支付
    async handleQuickPay() {
      this.payType = 1;
      this.dialogTips = false;
      this.handleSetupSure();
      this.pluginTraceSource();
    },
    buyCharPack() {
      this.dialogVisible = false;
      try {
        let TokenObj = getToken();
        let access_token = "";
        if (TokenObj) {
          TokenObj = JSON.parse(TokenObj);
          access_token = `${TokenObj.access_token}`;
        }
        // eslint-disable-next-line
        OpenBrowser(
          `https://www.writemall.com/details/2?token=${access_token}`
        );
      } catch (e) {
        console.log(e);
      }

      window.isifHaveBuyDialog();
      // try {
      //   //eslint-disable-next-line
      //   OpenBrowser("https://www.writemall.com/details/2");
      // } catch (e) {
      //   console.log(e);
      // }

      window.isifHaveBuyDialog();
    },
    //上传文件
    uploaderPop() {
      this.$store.commit("CHECK_USER_OPTIONS", {
        //忽略实名验证
        ignoreReal: true,
        //验证通过后回调
        afterCheck: () => {
          this.uploadVisible = true;
          this.fileList = [];
          //清空之前溯源任务队列
          delTracesourceQueue();
        },
      });
    },
    //文件上传成功
    onFileSuccess(file) {
      if (!file.isFolder) {
        this.fileList.push(1);
        if (this.fileList.length == file.uploader.files.length) {
          this.uploadVisible = false;
          this.$router.push({
            name: "TraceSourceFile",
            params: {
              payType: this.payType,
              setId: this.setUpId,
            },
          });
        }
      }
    },
    //文件上传失败
    onFileError(file) {
      if (!file.isFolder) {
        this.fileList.push(1);
        if (this.fileList.length == file.uploader.files.length) {
          this.uploadVisible = false;
          this.$router.push({
            name: "TraceSourceFile",
            params: {
              payType: this.payType,
              setId: this.setUpId,
            },
          });
        }
      }
    },
    handleSetupOpen() {
      this.dialogsetup = true;
      this.getTraceSourceSetUp();
    },

    async getTraceSourceSetUp() {
      const res = await getTraceSetup();
      if (res.code === 200) {
        this.payType = res.data.payType.toString();
        this.fieldStyle = res.data.fieldStyle.toString();
        // this.sourceLocation = res.data.sourceLocation.toString();
        this.setUpId = res.data.id;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    async handleSetupSure() {
      const data = {
        id: this.setUpId,
        payType: parseInt(this.payType),
        fieldStyle: parseInt(this.fieldStyle),
      };
      const res = await postTraceSetup(data);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "设置成功！",
        });
        this.dialogsetup = false;
        this.getTraceSourceSetUp();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    //溯源完成状态
    completeStatus(s) {
      return s == 2;
    },
    //获取完整编码的预览地址
    async previewPdf(source) {
      const params = {
        url: this.basePath + source,
      };
      const res = await previewUrl(params);
      if (res.code == 200) {
        this.fullPreviewPdf = res.data;
      }
    },
    //打开报表pdf
    async openPdf(pdf) {
      if (pdf) {
        this.$store.commit("playLifeLoading", true);
        await this.previewPdf(pdf);
        this.$store.commit("playLifeLoading", false);
        if (this.fullPreviewPdf) {
          window.open(this.fullPreviewPdf);
          this.fullPreviewPdf = "";
        }
      } else {
        this.$message({
          type: "success",
          message: "暂无报告",
        });
      }
    },
    // 生成二维码
    crateQrcode(pdf, fileName) {
      // const params = {
      //   fileName: fileName,
      //   url: pdf,
      // }
      // console.log(params.url)
      // console.log(params.fileName)
      // this.qr = new QrCode(this.$refs.qrcode_box, {
      //   text: `http://cloud.antiplagiarize.com/share?fileName=${params.fileName}&url=${params.url}`,
      //   correctLevel: 3,
      //   width: 128,
      //   height: 128,
      //   colorDark: '#000000',
      //   colorLight: '#ffffff',
      // })
      const params = {
        fileName: encodeURIComponent(fileName),
        url: encodeURIComponent(pdf),
      };
      let code = this.$store.state.user_info
        ? this.$store.state.user_info.recommendcodeVo.code
        : "";
      this.qr = new QrCode(this.$refs.qrcode_box, {
        text: `https://mall.writemall.com/share?fileName=${params.fileName}&url=${params.url}&code=${code}`,
        width: 128,
        height: 128,
        correctLevel: 3,
        colorDark: "#000000",
        colorLight: "#ffffff",
      });
    },
    // 打开分享二维码弹窗
    QrCodeShow(pdf, fileName) {
      this.QrCodeVisible = true;
      this.subIndex = -1;
      this.$nextTick(() => {
        let fileName1 = fileName;
        // let fileName1 = fileName + '溯源报告.pdf'
        this.crateQrcode(pdf, fileName1);
      });
    },
    // 关闭去除二维码
    handleCloseQrcode() {
      // console.log(1);
      this.$refs.qrcode_box.innerHTML = "";
    },
    //点击删除事件
    delHandler(reportId) {
      this.$confirm("是否删除该文件", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await this.delReport(reportId);
        this.gettraceList();
      });
    },
    //删除报表
    async delReport(reportId) {
      const params = { reportId };
      this.$store.commit("playLifeLoading", true);
      const res = await delHisTraceSource(params);
      this.$store.commit("playLifeLoading", false);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "删除成功",
        });
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    currentChange(cur) {
      this.pageNum = cur;
      this.gettraceList();
    },
    sortClick(item, index) {
      // console.log(item);
      item.sort = !item.sort;
      this.currentSortIndex = index;
      if (item.sort) {
        this.sortOrder = "1";
      } else {
        this.sortOrder = "2";
      }
      if (item.title == "溯源时间") {
        this.sort = "1";
      } else if (item.title == "原创率") {
        this.sort = "2";
      }

      this.gettraceList();
    },
    handleClickDep() {
      // if (!getToken("auth_token")) {
      //   this.$message({
      //     message: "请先登录！",
      //     duration: 1500,
      //   });

      //   return window.loginFun();
      // }
      this.$router.push("/depthLearn");
    },
    // 获取表格数据
    async gettraceList(after) {
      const data = {
        page: this.pageNum,
        limit: this.pageSize,
        sort: this.sort,
        sortType: this.sortOrder,
        key: this.searchKey,
      };
      if (!this.noNeedLoading) {
        this.$store.commit("playLifeLoading", true);
      }
      const res = await findTraceReport(data);
      if (typeof after === "function") after();
      this.$store.commit("playLifeLoading", false);
      if (res.code === 200) {
        this.traceReportList = res.data.data;

        this.traceReportList.forEach((item) => {
          switch (item.reportType) {
            case 0:
              item.num = "07";
              break;
            case 1:
              item.num = "07";
              break;
            case 2:
              item.num = "09";
              break;
            case 3:
              item.num = "11";
              break;
            case 4:
              item.num = "12";
              break;
            case 5:
              item.num = "11";
              break;
          }
        });
        // console.log(this.traceReportList);
        this.traceReportTotal = res.data.total;
        // this.pageCount = Math.ceil(this.traceReportTotal / this.pageSize);
      } else {
        this.traceReportList = [];
        this.traceReportTotal = 0;
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    async searchKeyFile() {
      const data = {
        page: 1,
        limit: this.pageSize,
        key: this.searchKey,
      };
      this.$store.commit("playLifeLoading", true);
      const res = await findTraceReport(data);
      this.$store.commit("playLifeLoading", false);
      if (res.code === 200) {
        this.traceReportList = res.data.data;
        this.traceReportList.forEach((item) => {
          switch (item.reportType) {
            case 0:
              item.num = "07";
              break;
            case 1:
              item.num = "07";
              break;
            case 2:
              item.num = "09";
              break;
            case 3:
              item.num = "11";
              break;
            case 4:
              item.num = "12";
              break;
            case 5:
              item.num = "11";
              break;
          }
        });
        this.traceReportTotal = res.data.total;
        this.pageCount = Math.ceil(this.traceReportTotal / this.pageSize);
      } else {
        this.traceReportList = [];
        this.traceReportTotal = 0;
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    async findFileByDate() {
      const data = {
        page: this.pageNum,
        limit: this.pageSize,
        date: this.selectDate,
      };
      this.$store.commit("playLifeLoading", true);
      const res = await findTraceReport(data);
      this.$store.commit("playLifeLoading", false);
      if (res.code === 200) {
        this.traceReportList = res.data.data;
        this.traceReportList.forEach((item) => {
          switch (item.reportType) {
            case 0:
              item.num = "07";
              break;
            case 1:
              item.num = "07";
              break;
            case 2:
              item.num = "09";
              break;
            case 3:
              item.num = "11";
              break;
            case 4:
              item.num = "12";
              break;
            case 5:
              item.num = "11";
              break;
          }
        });
        this.traceReportTotal = res.data.total;
        this.pageCount = Math.ceil(this.traceReportTotal / this.pageSize);
      } else {
        this.traceReportList = [];
        this.traceReportTotal = 0;
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    /***
     * 点击在线授权
     * @param { String } ids 没有ids表示无在线授权
     * @param { Number } id 当前文件id
     */
    showAuthorize(ids, id, item) {
      if (!ids) return;
      this.showAuthorizeList = true;
      this.sourecFileType = item.reportType;
      this.$refs["traceAuthorizeList"].findAuthorizeList(id);
    },
    downLoadWroks() {
      try {
        // eslint-disable-next-line
        DownloadDocument(
          this.$refs["dataAnalysisDetails"].openLibraryFile.filename,
          this.$store.state.onlineBasePath +
            this.$refs["dataAnalysisDetails"].openLibraryFile.url
        );
      } catch (e) {
        console.log(e);
      }
      this.buyIngDialog = false;
    },
    openWorks() {
      try {
        // eslint-disable-next-line
        OpenDocument2(
          this.$refs["dataAnalysisDetails"].openLibraryFile.filename,
          this.$store.state.onlineBasePath +
            this.$refs["dataAnalysisDetails"].openLibraryFile.url
        );
      } catch (e) {
        console.log(e);
      }
      this.buyIngDialog = false;
    },
    //显示授权许可信息
    async showAuthorizeInfo(item) {
      console.log(item);
      if (item.fileType == "07") {
        await this.$refs["dataAnalysisDetails"].getCheckPortfolioAuthorization(
          item.textMd5
        );
        if (this.$refs["dataAnalysisDetails"].openLibraryFile.payStatus) {
          console.log("1111");
          this.buyIngDialog = true;
          this.isDetails = false;
        } else {
          this.isDetails = true;
        }
      } else {
        this.$nextTick(() => {
          this.$refs["traceAuthorize"].authorization(item);
        });
      }
    },
    sortType() {},
    //下载报表
    download(url, name) {
      name = name.substring(0, name.lastIndexOf("."));
      const suffix = url.slice(url.lastIndexOf("."));
      const a = document.createElement("a");
      a.href =
        this.basePath +
        url +
        "?attname=" +
        (suffix == ".pdf" ? name + ".pdf" : name + suffix);
      a.click();
    },
    //下载操作菜单
    selectMenu(i) {
      if (this.subIndex === i) {
        return (this.subIndex = -1);
      }
      this.subIndex = i;
    },

    sourceReportOption(item) {
      console.log(item);
      window.open(
        this.$store.state.onlineBasePath +
          (item.reportWordUrl
            ? item.reportWordUrl + "?attname=溯源报告-" + item.fileName
            : item.reportUrl + "?attname=溯源报告-" + item.fileName),
        "_blank"
      );
    },
    sourceFile(item) {
      console.log(item);
      window.open(
        this.$store.state.onlineBasePath +
          item.url +
          "?attname=" +
          item.fileName,
        "_blank"
      );
    },
    //选择菜单项
    // selectMenuItem(e, name) {
    //   const target = e.target;
    //   const suffix = name.substring(name.lastIndexOf(".") + 1);
    //   if (target.title == "溯源报告") {
    //     if (suffix == "docx" || suffix == "doc") {
    //       if (target.dataset.subitem && target.dataset.subitem !== "") {
    //         try {
    //           // eslint-disable-next-line
    //           OpenDocument2(name, this.$store.state.onlineBasePath + target.dataset.subitem);
    //         } catch (e) {
    //           console.log(e);
    //         }
    //       }
    //     } else {
    //       this.download(target.dataset.subitem, name);
    //     }
    //   } else if (target.title == "源文件") {
    //     if (target.dataset.subitem && target.dataset.subitem !== "") {
    //       try {
    //         // eslint-disable-next-line
    //         OpenDocument2(name, this.$store.state.onlineBasePath + target.dataset.subitem);
    //       } catch (e) {
    //         console.log(e);
    //       }
    //     }
    //   }
    //   this.subIndex = -1;
    // },
    clickBlankHandler() {
      this.subIndex = -1;
    },
    // 详情弹窗
    viewDetail(val) {
      this.infoFilesPopup = true;
      this.valInfo = val;
    },
    format(time) {
      const d = new Date(time);
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let date = d.getDate();
      month = month < 10 ? "0" + month : month;
      date = date < 10 ? "0" + date : date;
      return year + "-" + month + "-" + date;
    },
  },
  watch: {
    // firstTraced(newVal) {
    //   if (newVal) {
    //     this.$nextTick(() => {
    //       this.tableHeight =
    //         parseInt(
    //           window.getComputedStyle(this.$refs["tablewrap"], null).height
    //         ) - 87;
    //     });
    //   }
    // },
  },
  components: {
    OfficeNav,
    TraceSourceAuthorizationList,
    TraceSourceAuthorizationInfo,
    // Pager,
    UploadBigFile,
    dataAnalysisDetails,
    IdiomEditor,
  },
  created() {
    // this.sourceResultProgress();
    this.getDepthLearnList();
    this.noNeedLoading = true;
    this.gettraceList(() => {
      this.noNeedLoading = false;
    });
    this.getTraceSourceSetUp();
    this.$bus.$on("firstTraced", () => {
      this.firstTraced = true;
    });
  },
  mounted() {
    document.addEventListener("click", this.clickBlankHandler);
    /**
     * 接收左侧上传文件参数
     * @param { String } url 文件上传地址
     * @param { Number } wordCount 字数
     * @param { String } fileName 文件名
     */
    window.getWordSource = (url, wordCount, fileName) => {
      this.srcFileUrl = url;
      this.wordCount = wordCount;
      this.srcFileName = fileName;
      this.getFontNumber();
    };
    document.body.onclick = () => {
      // console.log(this.isShowHistoryList);
      if (this.isShowHistoryList) {
        this.isShowHistoryList = false;
      }
    };
    this.isShowDriver();
  },
  destroyed() {
    document.removeEventListener("click", this.clickBlankHandler);
  },
};
</script>

<style lang="scss" scoped>
.dataAnalysisDetails {
  position: absolute;
  left: 0;
  top: 25px;
  background: #fff;
  width: 100%;
}
.office-tracesource {
  padding: 0 30px 30px;
  height: calc(100vh - 66px);
  .kong {
    text-align: center;
  }
  .office-nav {
    @include flex-between;
    line-height: normal;
    min-width: 600px;
    .progress {
      width: 230px;
      display: flex;
      align-items: center;
      color: #999999;
      font-size: 13px;
      padding-right: 20px;
      .el-progress {
        flex: 1;
        margin-left: 10px;
      }
      ::v-deep .el-progress-bar__innerText {
        // color: red;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .left {
      @include flex-start;

      > div {
        @include flex-start;
        margin-left: 20px;
        cursor: pointer;

        > span {
          margin-left: 5px;
          font-size: 13px;
          font-weight: 500;
          color: #000;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .char {
      margin-right: 20px;
      font-size: 13px;
      font-weight: 500;
      color: #999;
    }

    .search {
      @include flex-start;

      > div {
        height: 30px;

        ::v-deep .el-input {
          height: 30px;

          .el-input__inner {
            height: 30px;
            line-height: 30px;
          }

          .el-input__icon {
            line-height: 30px;
          }

          input {
            font-size: 14px;
            font-weight: 400;
            color: #999999;

            &::-ms-clear {
              display: none;
            }
          }
        }
      }

      .query-date {
        margin-left: 20px;
      }
    }
  }

  .office-tracesource-table {
    .search_nav {
      @include flex-between;
      padding: 0 10px;
      .sort {
        display: flex;
        .sort_item {
          margin-right: 20px;
          font-size: 12px;
          color: #666;
          cursor: pointer;
          .is-active {
            color: #4587ff;
          }
          i {
            display: inline-block;
            width: 9px;
            height: 13px;
            margin-left: 6px;
          }
          .up {
            @include backgroundGroup("~assets/images/up.png");
          }

          .down {
            @include backgroundGroup("~assets/images/down.png");
          }
        }
      }

      .search-left {
        @include flex-start;
        width: 291px;
        height: 41px;
        background: #ffffff;
        border: 1px solid #d9dee4;
        border-radius: 20px;
        ::v-deep .el-input {
          // margin-left: 140px;
          flex: 1;
          // margin-right: 20px;
          // width: 400px;
          .el-input__inner {
            border: none;
            padding: 0px;
            // margin: 0 10px;
            margin-left: 10px;
            width: 90%;
          }
          .el-input__inner::-webkit-input-placeholder {
            /* 使用webkit内核的浏览器 */
            // color: #e0484b;
            line-height: 40px;
          }
        }
        .search-btn {
          // background-color: #3683f2;
          height: 100%;
          // border-radius: 5px;
          width: 50px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            color: #4587ff;
            font-size: 18px;
          }
          // img {
          //   cursor: pointer;
          //   position: absolute;
          //   top: 50%;
          //   left: 50%;
          //   transform: translate(-50%, -50%);
          // }
        }
      }
    }
    .traceReportList {
      height: calc(100% - 42px);
      overflow: auto;
      font-size: 14px;
      margin-top: 10px;
      .trace_item {
        margin: 6px auto;
        padding: 10px 5px;
        width: 97%;
        cursor: pointer;
        // border-bottom: 1px solid #ccc;
        // box-shadow: 0px 0px 10px 2px #eee;
        border-bottom: 0.0625rem solid #efefef;
        display: flex;
        align-items: center;
        height: 60px;
        position: relative;
        > img {
          width: 60px;
          height: 60px;
        }
        .more {
          position: absolute;
          right: 5%;
          bottom: 10%;
          cursor: pointer;
        }
        .states {
          position: absolute;
          right: 50px;
          top: 30%;
          width: 50px;
          height: 20px;
          margin-top: -9px;
          font-size: 12px;
          font-weight: 800;
          text-align: center;
          line-height: 20px;
          color: #fff;
          z-index: 1;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            z-index: -1;
          }
        }

        .gray {
          &::before {
            background: url("~assets/images/rhombus-gray.png") no-repeat;
            background-size: 100% 100%;
          }

          color: #999;
        }

        .green {
          &::before {
            background: url("~assets/images/rhombus-green.png") no-repeat;
            background-size: 100% 100%;
          }
        }

        .orange {
          &::before {
            background: url("~assets/images/rhombus-orange.png") no-repeat;
            background-size: 100% 100%;
          }
        }

        .blue {
          &::before {
            background: url("~assets/images/rhombus-blue.png") no-repeat;
            background-size: 100% 100%;
          }
        }
        .info {
          // display: flex;
          // flex-direction: column;
          // justify-items: center;
          height: 100%;
          width: 70%;
          .title {
            // font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 30px;
            height: 30px;
            padding-left: 15px;
            flex: 1;
          }
          .other {
            color: gray;
            flex: 1;
            display: flex;
            width: 100%;
            align-items: center;
            // justify-content: space-around;
            line-height: 30px;
            height: 30px;
            padding-left: 15px;
            .evaluate-wrap {
              width: 50%;
              .original {
                display: flex;
                align-items: center;
              }
              .homology {
                display: flex;
                align-items: center;
              }
              img {
                width: 18px;
                height: 18px;
                margin: 0 10px;
              }
            }
            .time {
              width: 50%;
            }
          }
        }
        &:hover {
          background: #ffffff;
          box-shadow: 3px 1px 16px 2px rgba(107, 131, 167, 0.1);
          border-radius: 5px;
        }
      }
    }
  }

  &-table {
    height: calc(100vh - 170px);
    margin-top: 10px;
    padding: 0 10px;

    ::v-deep .el-table::before {
      background-color: transparent;
    }

    ::v-deep .el-table .caret-wrapper {
      display: inline-block;
    }

    ::v-deep .el-table td.el-table__cell {
      overflow: hidden;
      border-bottom: none;

      &:first-child {
        @include noselect;

        .cell {
          overflow: visible;
        }
      }

      &:last-child {
        overflow: visible;

        .cell {
          overflow: visible;
        }
      }

      .cell {
        @include ellipsis;
        position: relative;

        .states {
          position: absolute;
          left: 30px;
          top: 50%;
          width: 50px;
          height: 18px;
          margin-top: -9px;
          font-size: 12px;
          font-weight: 800;
          text-align: center;
          line-height: 18px;
          color: #fff;
          z-index: 1;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            z-index: -1;
          }
        }

        .gray {
          &::before {
            background: url("~assets/images/rhombus-gray.png") no-repeat;
          }

          color: #999;
        }

        .green {
          &::before {
            background: url("~assets/images/rhombus-green.png") no-repeat;
          }
        }

        .orange {
          &::before {
            background: url("~assets/images/rhombus-orange.png") no-repeat;
          }
        }

        .blue {
          &::before {
            background: url("~assets/images/rhombus-blue.png") no-repeat;
          }
        }

        .evaluate-wrap {
          @include noselect;
          font-size: 12px;
          font-weight: 500;

          .nothing {
            color: #999;
          }

          .original,
          .homology {
            @include flex-start;

            .levelIcon {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              vertical-align: middle;
            }

            .txt {
              margin-left: 5px;
              color: #4587ff;
            }
          }

          .homology {
            .txt {
              &.is-homology {
                color: #e15240;
              }

              &.no-homology {
                color: #4587ff;
              }
            }
          }
        }

        .authorize {
          font-size: 12px;
          font-weight: 500;
          cursor: pointer;
        }

        .is-blue {
          color: #4587ff;
          cursor: pointer;
        }

        .is-gray {
          color: #999999;
        }

        .operate {
          @include flex-between;

          span {
            @include flex-start;
            cursor: pointer;

            i {
              position: relative;
              width: 16px;
              height: 16px;
            }
          }

          .more .submenu {
            width: 114px;
          }

          .more,
          .down {
            .submenu {
              @include noselect;

              &.submenu-active {
                @include flex-center(column);
                justify-content: normal;
                align-items: normal;
              }

              display: none;
              position: absolute;
              z-index: 1000;
              top: 20px;
              left: -75px;
              width: 99px;
              padding: 15px 0;
              background: #ffffff;
              box-shadow: 0px 4px 8px 1px rgba(31, 34, 40, 0.17);
              border-radius: 5px;
              box-sizing: border-box;

              span {
                padding: 0 15px;
                font-size: 12px;
                font-weight: 500;
                color: #999999;
                line-height: 1;
                cursor: pointer;

                &:hover {
                  color: #333333;
                }

                &:first-child ~ span {
                  margin-top: 20px;
                }
              }
            }
          }

          .more i,
          .view i,
          .down i,
          .share i,
          .delete i {
            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              background-size: 100% 100%;
            }
          }

          .more i::before {
            background: url("~assets/images/more.png") no-repeat;
          }

          .more:hover i::before {
            background: url("~assets/images/more-blue.png") no-repeat;
          }

          .view i::before {
            background: url("~assets/images/report-view-gray.png") no-repeat;
          }

          .view:hover i::before {
            background: url("~assets/images/report-view-blue.png") no-repeat;
          }

          .down i::before {
            background: url("~assets/images/report-down-gray.png") no-repeat;
          }

          .down:hover i::before {
            background: url("~assets/images/report-down-blue.png") no-repeat;
          }

          .share i::before {
            background: url("~assets/images/report-share-gray.png") no-repeat;
          }

          .share:hover i::before {
            background: url("~assets/images/report-share-blue.png") no-repeat;
          }

          .delete i::before {
            background: url("~assets/images/delete.png") no-repeat;
          }

          .delete:hover i::before {
            background: url("~assets/images/delete-blue.png") no-repeat;
          }
        }
      }
    }

    ::v-deep .el-table th.el-table__cell.is-leaf {
      border-bottom: none;
    }
  }

  &-footer {
    @include noselect;
    // margin: 50px auto;
    margin-top: 15px;

    ::v-deep .el-pagination {
      text-align: center;
    }
  }

  .qrcode {
    ::v-deep .el-dialog {
      // top: 50%;
      // transform: translateY(-50%);
      margin-top: 25vh !important;
      width: 500px;
      height: 390px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);

      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        line-height: 42px;

        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }

        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }

      .el-dialog__body {
        padding: 65px 20px;

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .notes {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
          }

          .imgBox {
            margin-top: 30px;
          }
        }
      }
    }
  }

  // 上传弹窗
  .upload {
    ::v-deep .el-dialog {
      width: 60%;
      min-height: 340px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);

      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        display: flex;
        align-items: center;

        .el-dialog__title {
          margin-left: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }

        .el-dialog__headerbtn {
          top: 15px;
        }
      }

      .el-dialog__body {
        padding: 27px 30px 0px;

        .content-text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
      }
    }
  }

  .setupCenter {
    ::v-deep .el-dialog {
      width: 513px;
      height: 392px;
      background: #ffffff;
      border: 1px solid #e8eaef;
      box-shadow: 0px 3px 16px 0px rgba(129, 135, 144, 0.22);
      border-radius: 12px;

      .el-dialog__body {
        padding: 43px 24px 0 24px;

        .el-radio__inner {
          border-radius: 0;
          width: 16px;
          height: 16px;
        }

        .el-radio__input.is-checked .el-radio__inner {
          border-color: #4587ff;
          background: #4587ff;
        }

        .el-radio__input.is-checked .el-radio__inner::after {
          content: "";
          width: 6px;
          height: 3px;
          border: 2px solid white;
          border-top: transparent;
          border-right: transparent;
          text-align: center;
          display: block;
          position: absolute;
          top: 4px;
          left: 3px;
          // vertical-align: middle;
          transform: rotate(-62deg);
          border-radius: 2px;
          background: none;
        }

        .el-radio__label {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
        }

        .content {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;

          .content-title {
            margin-bottom: 21px;
          }
        }

        .contentStyle {
          margin-top: 40px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;

          .contentStyle-item {
            display: flex;
            margin-top: 20px;

            .contentStyle-item-label {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
              margin-right: 30px;
              line-height: 30px;
            }

            .contentStyle-item-radio {
              width: 278px;
              line-height: 30px;
              .el-radio:last-child {
                margin-left: 0;
              }
            }
          }
        }

        .footer {
          display: flex;
          justify-content: flex-end;
          margin-top: 35px;

          .footer-btn {
            width: 68px;
            height: 40px;
            background: #f1f1f1;
            border-radius: 5px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            line-height: 40px;
            text-align: center;
            margin-right: 14px;
            cursor: pointer;

            &:last-of-type {
              margin-right: 14px;
            }
          }

          .sure {
            color: #fff;
            background: #3683f2;
          }
        }
      }
    }
  }

  .tips {
    ::v-deep .el-dialog {
      display: flex;
      flex-direction: column;
      // top: 50%;
      // transform: translateY(-50%);
      margin-top: 25vh !important;
      width: 340px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);

      .el-dialog__header {
        padding: 12px 12px 0px;

        .el-dialog__title {
          font-size: $font-size-small;
          font-family: PingFang SC;
          font-weight: 400;
          color: $color-text-gray;
        }

        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }

      .el-dialog__body {
        padding: 15px 39px;
        flex: 1;

        .content {
          font-size: $font-size-medium-x;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-black;
          line-height: 26px;
          display: flex;
          align-items: normal;

          span {
            color: #eb441e;
          }

          .content-icon {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            @include backgroundGroup("~assets/images/del-error.png");
            transform: translateY(4px);
            margin-right: 9px;
          }
        }

        .active {
          margin-top: 20px;
        }

        .footer {
          display: flex;
          justify-content: space-between;
          margin-top: 33px;

          .footer-btn {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-bg-heightlight;
            cursor: pointer;
          }
        }
      }
    }
  }

  // 详情弹窗
  .addFil {
    margin: 0 auto;
    width: 70%;

    .box {
      margin-top: 20px;

      &:nth-child(1) {
        margin-top: 0;
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;

        display: flex;

        span {
          &:nth-child(2) {
            flex: 1;
          }
        }

        .download {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .source-timespan {
    ::v-deep .el-dialog {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      margin-top: 25vh !important;
      width: 423px;
      height: 258px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      border-radius: 6px;
      .el-dialog__header {
        padding: 10px 10px;
        background-color: #f4f6f7;
        line-height: 1;
        border-radius: 6px;
        width: 100%;
        box-sizing: border-box;
        .el-dialog__title {
          font-size: 14px;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: 15px;
          right: 12px;
        }
      }
      .el-dialog__body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 20px 20px;
        .content {
          flex: 1;
          @include flex-center;
          justify-content: stretch;
          .time-wrap {
            flex: 1;
            // @include flex-between;
            display: flex;
            justify-content: center;
            align-items: center;
            .date-wrap {
              @include flex-center(column);
              justify-content: stretch;
              align-items: stretch;
              .title {
                text-align: center;
              }
              .el-date-editor.el-input {
                width: 180px;
                margin-top: 10px;
                .el-input__inner {
                  padding-left: 60px;
                }
              }
            }
            .bar {
              margin-top: 20px;
              vertical-align: middle;
              color: #999;
            }
          }
        }
        .footer {
          // @include flex-between(space-around);
          // display: flex;
          align-items: center;
          margin-top: 20px;
          text-align: center;
          > span {
            @include noselect;
            display: inline-block;
            width: 110px;
            height: 30px;
            border-radius: 5px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            background-color: #4587ff;
            cursor: pointer;
            &.cancel {
              background-color: #fff;
              border: 1px solid #4587ff;
              color: #4587ff;
            }
          }
          .cancel {
            margin-left: 20px;
          }
        }
      }
    }
  }
  .arousel {
    width: 100%;
    // display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100% - 100px);
    justify-content: space-evenly;
    padding: 1% 5%;
    box-sizing: border-box;

    .title_box {
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 200;
      color: #ccc;
      margin-bottom: 30px;
    }

    // 搜索
    .search_box {
      width: 654px;
      position: relative;
      .historyList {
        font-size: 14px;
        font-family: "Microsoft YaHei" !important;
        width: 542px;
        box-sizing: border-box;
        border-left: 1px solid #3683f2;
        border-right: 1px solid #3683f2;
        border-bottom: 1px solid #3683f2;

        margin-top: -7px;
        position: absolute;
        // bottom: -293px;
        z-index: 99;
        max-height: 300px;
        background-color: #fff;
        border-radius: 0 0 10px 10px;
        > div:first-child {
          margin-top: 7px;
        }
        > div {
          color: #606266;
          // padding: 12px 10px;
          margin-left: 15px !important;
          width: 98%;
          height: 30px;
          line-height: 30px;
          margin: 0 auto;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            // background: #eee;
            color: #3683f2;
          }
        }
        .option {
          display: flex;
          justify-content: flex-end;
          &:hover {
            color: #999;
          }
          > span:first-child {
            margin-right: 10px;
          }
          > span:hover {
            color: #3683f2;
          }
        }
      }
      .search {
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        // padding: 5px 5px 5px 0;

        border-radius: 10px;
        border: 1px solid #3683f2;

        .search_left {
          font-family: "Microsoft YaHei" !important;
          ::v-deep .el-select {
            .el-input {
              .el-input__inner {
                width: 100px;
                border: 0px;
                font-family: "Microsoft YaHei" !important;
              }
            }
          }
        }

        .inp {
          height: 26px;
          border-left: 1px solid #e4e4e4;
          padding-left: 20px;
          font-size: $font-size-medium;
          font-family: "Microsoft YaHei" !important;
          font-weight: 500;
          color: $color-text-gray;
          flex: 1;
        }

        .search_btn {
          // width: 40px;
          height: 42px;
          padding: 0 10px;
          background: #3683f2;
          // @include backgroundGroup("~assets/images/idiom.png");
          @include flex-center();
          border-radius: 0 5px 5px 0;
          cursor: pointer;
          > img:first-child {
            width: 52px;
            height: 22px;
            margin: 0 10px;
          }
          // .search_icon {
          //   width: 17px;
          //   height: 17px;
          //   @include backgroundGroup("~assets/images/idiom.jpg");
          // }
        }
      }

      .keyword_box {
        margin-top: 20px;
        display: flex;

        .keyword_item {
          font-size: 14px;
          color: #666666;
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }

    .recommend_box {
      width: 100%;
      margin-top: 30px;
      .top {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .txt {
          cursor: pointer;
          font-size: 14px;
          //font-weight: bold;
          display: flex;
          align-items: center;
          img {
            // width: 200px;
            height: 25px;
          }
          span {
            margin-left: 12px;
            font-family: "黑体";
            font-size: 14px;
            // color: #4587ff;
          }
        }
        .txt1 {
          cursor: pointer;
          font-size: 14px;
          color: #999999;
        }
      }

      .bottom {
        .article_item {
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          align-items: center;
          white-space: nowrap;
          cursor: pointer;
          margin: 20px 0;
          font-size: 14px;

          &:nth-child(1) {
            span {
              color: #f26d5f;
            }
          }
          &:nth-child(2) {
            span {
              color: #ff8200;
            }
          }
          &:nth-child(3) {
            span {
              color: #4587ff;
            }
          }

          span {
            font-weight: bold;
            margin-right: 10px;
            color: #999999;
          }
        }
      }
    }

    h3 {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #bbb;
      margin: 18px 0;
    }
    .arousel_content {
      display: flex;
      width: 100%;
      height: 100%;
      min-width: 626px;
      min-height: 338px;
      border: 1px solid #eaeef1;
      .text_right {
        flex: 1;
        height: 100%;
        min-height: 338px;
        background: #fff;
        margin-left: 45px;
        color: #333;
      }
      ul {
        width: 200px;
        height: 100%;
        min-height: 338px;
        background: #fbfcfd;

        font-size: 12px;
        padding-left: 17px;
        box-sizing: border-box;
        li {
          display: flex;
          list-style: none;
          height: 48px;
          line-height: 48px;
          overflow: hidden;
          .box {
            margin-top: 1px;
          }
          span {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #4587ff;

            margin-right: 13px;
          }
          .span {
            margin-right: 7px;
            img {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
</style>
